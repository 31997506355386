import React, { useEffect } from "react"
import './restaurant-page.scss'

const RestaurantPage = () => {
    return (
        <div className="order-page-container">
            <h1>RESTAURANT :D</h1>
        </div>
    )
}

export default RestaurantPage;